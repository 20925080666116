






import { Component, Vue } from "vue-property-decorator";
import AllBookings from "@/components/AllBookings.vue";

@Component({
  components: {
    AllBookings
  }
})
export default class GuestInfo extends Vue {
  data() {
    return {
      errors: []
    };
  }

  get userAuthenticated() {
    return true;
  }

  get bookingInfo() {
    return this.$store.state.bookingInfo;
  }

  get hotelInfo() {
    return this.$store.state.hotelInfo;
  }

  get authUser() {
    return this.$store.state.authProfile;
  }
}
